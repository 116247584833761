.popup {
    position: fixed;
    top: 0;
    right: 0;
    bottom: -100%;
    left: 0;
    z-index: 999999999;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s;
    // display: none;

    &--open{
        transition: all 0.2s;
        opacity: 1;
        visibility: visible;
        bottom: 0;
    }

    &__background {
        background: $bg-popup;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__outside {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90%;
        max-width: 950px;
        background: $color-body;
        min-height: 120px;
        max-height: 450px;
        overflow: hidden;
        padding: 20px 30px;
        position: relative;
    }

    &__inside {
        width: 100%;
        min-height: 250px;
        height: 50vh;
        max-height: 750px;
        overflow-y: scroll;
    }

    &__wrapper {
        padding-bottom: 100px;
    }
}