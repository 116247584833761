/**
*   Home banner
**/
.home-banner{
    position: relative;

    padding-top: 200px;
    padding-bottom: 130px;

    @include media-breakpoint-down(md){
        padding-top: 170px;
        padding-bottom: 100px;
    }

    @include media-breakpoint-down(tn){
        padding-top: 130px;
        padding-bottom: 50px;
    }

    &__img{
        position: absolute;
        top: 0;

        width: 50%;
        height: 100%;

        background-repeat: no-repeat;
        background-size: auto;
    }

    &__img-first{
        left: 0;

        background-image: url(../img/maz.png);
        background-position: right;

        @include media-breakpoint-down(md){
            background-position-x: 85%;
        }

        @include media-breakpoint-down(sm){
            background-position: right;
            background-size: 200%;
        }
    }

    &__img-last{
        right: 0;

        background-image: url(../img/maz-3.png);
        background-position-x: left;
        background-position-y: 75%;

        @include media-breakpoint-down(md){
            background-position-x: 10%;
        }

        @include media-breakpoint-down(sm){
            background-position: left;
            background-size: 200%;
        }
    }

    &__content{
        text-align: center;
        text-transform: uppercase;
        font-size: 44px;
        font-family: $font-title;
        text-shadow: 1px 0 0 $color-font;

        @include media-breakpoint-down(md){
            font-size: 36px;
        }

        @include media-breakpoint-down(sm){
            font-size: 30px;
        }

        p{
            margin-bottom: 0;
        }
    }

    &__icon{
        position: absolute;
        bottom: -50px;
        left: 0;
        right: 0;

        display: inline-block;

        color: $color-font;
        font-size: 14px;

        transform: rotate(180deg);
        transition: color 0.3s;

        &:hover{
            color: $color-accent;
            transition: color 0.3s;
            text-decoration: none;
        }
    }
}