.cookiesInfo{
    h2 {
        font-size: 2.25rem;
        font-family: $font-title;
        text-align: center;
        margin-bottom: 25px;
    }
    
    &__heading {
        font-weight: 700;
    }

    p {
        padding-bottom: 20px;
        margin-bottom: 0;

        color: $color-font-light;

        a{
            color: $color-font-link;

            &:hover{
                color: $color-font-link-hover;
                text-decoration: none;
            }
        }
    }

    &__buttonRow {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: $color-body;
        padding: 23px 0;
        border-top: 1px solid $color-border;
    }

    &__button {
        background: $bg-button;
        color: $color-body;
        cursor: pointer;
        font-weight: 700;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        min-height: 45px;
        height: 2.64706vw;
        max-height: 55px;
        min-width: 120px;
        margin: 0 auto;
        text-transform: uppercase;
        padding: 0 10px;
    }

    &__button:focus,
    &__button:hover {
        background: $bg-button-hover;
        color: $color-body;
        text-decoration: none;
    }
}