// Bootstrap configuration
$grid-columns: 12;
$grid-gutter-width: 30px;

$grid-breakpoints: (
    tn: 0,
    xs: 576px,
    sm: 768px,
    md: 1100px,
    rg: 1350px,
    lg: 1400px
);

$container-max-widths: (
    xs: 540px,
    sm: 720px,
    md: 960px,
    rg: 1200px
);

// Load bootstrap
@import '../../../node_modules/bootstrap/scss/bootstrap';