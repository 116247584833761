* {
    box-sizing: border-box;
}

html,
body {
    -webkit-overflow-scrolling: touch;
}

html {
    font-size: 100%;
}

body,
button,
input {
    font-family: $font-regular;
    font-size: 16px;
    line-height: 1.6;
    font-weight: 400;
    font-style: normal;
    outline: none;
}

body {
    background: $color-body;
    color: $color-font;
    overflow-y: scroll;
    overflow-x: hidden;
}

img {
    max-width: 100%;
    height: auto;
}