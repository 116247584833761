// @import 'iconfont';
@import 'mixins';

/**
 * Czcionki
 * @include font-face({font-name}, '../fonts/{font-folder}/{font-file-name}', {font-weight}, {font-style}, {font-type: woff ttf woff2});
 */
 //  Aileron
 @include font-face('Aileron', '../fonts/Aileron/Aileron-Thin', 200, 'normal', otf);
 @include font-face('Aileron', '../fonts/Aileron/Aileron-Light', 300, 'normal', otf);
 @include font-face('Aileron', '../fonts/Aileron/Aileron-Regular', 400, 'normal', otf);
 @include font-face('Aileron', '../fonts/Aileron/Aileron-Black', 800, 'normal', otf);
 
 //  Didot
 @include font-face('Didot', '../fonts/Didot/DidotLTPro-Roman', 700, normal, woff);