/**
*   Footer
**/
.footer{
    padding-top: 25px;
    padding-bottom: 25px;

    @include media-breakpoint-down(xs){
        padding-top: 15px;
        padding-bottom: 15px;
    }

    &__text{
        font-weight: 200;
        text-align: center;
        font-size: 22px;

        p{
            margin-bottom: 0;
        }

        @include media-breakpoint-down(sm){
            font-size: 20px;
        }

        @include media-breakpoint-down(xs){
            font-size: 14px;
        }
    }
}