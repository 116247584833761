/**
*   Home video
**/
.home-video{
    padding-top: 180px;
    padding-bottom: 180px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url(../img/home-video-bg.png);

    @include media-breakpoint-down(md){
        padding-top: 100px;
        padding-bottom: 100px;
    }

    @include media-breakpoint-down(sm){
        z-index: 0;
        position: relative;
    }

    @include media-breakpoint-down(tn){
        padding-top: 50px;
        padding-bottom: 50px;
    }

    iframe{

        @include media-breakpoint-down(md){
            height: 500px;
        }

        @include media-breakpoint-down(sm){
            height: 400px;
        }

        @include media-breakpoint-down(xs){
            height: 300px;
        }

        @include media-breakpoint-down(tn){
            height: 200px;
        }
    }
}