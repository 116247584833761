/**
*   Header
**/
.header{
    position: absolute;
    left: 0;
    top: 30px;
    right: 0;
    z-index: 9999;

    margin: 0 auto;

    text-align: center;

    &-logo{
        display: inline-block;
        max-width: 350px;

        @include media-breakpoint-down(md){
            max-width: 300px;
        }

        @include media-breakpoint-down(sm){
            max-width: 250px;
        }

        @include media-breakpoint-down(tn){
            max-width: 200px;
        }
    }
}