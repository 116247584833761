// Colors
$color-body: #fff;
$color-font: #000000;
$color-accent: #ffd5d4;
$color-font-light: #2a3539;
$color-font-link: #292929;
$color-font-link-hover: #7d7d7d;
$color-border: #dcdcdc;

// Background
$bg-popup: rgba(0,26,40,.7);

// Button
$bg-button: #feb2be;
$bg-button-hover: #ec9eab;

// Fonts
$font-regular: 'Aileron', sans-serif;
$font-title: 'Didot', sans-serif;