/**
*   Home information
**/
.home-information{
    position: relative;

    padding-top: 50px;
    padding-bottom: 300px;

    @include media-breakpoint-down(md){
        padding-bottom: 200px;
    }

    @include media-breakpoint-down(tn){
        padding-top: 30px;
        padding-bottom: 100px;
    }

    &__img{
        position: absolute;
        z-index: -1;
    }

    &__img-first{
        left: 0;
        right: 10px;
        top: 0;

        margin: 0 auto;
        width: 100%;
        height: 700px;
        
        background-position: center 10%;
        background-size: 100% 970px;
        background-repeat: no-repeat;
        background-image: url(../img/Hue_Saturation8.png);

        @include media-breakpoint-down(md){
            height: 500px;

            background-position: center 20%;
            background-size: 100% 770px;
        }

        @include media-breakpoint-down(tn){
            height: 300px;

            background-position: center 0%;
            background-size: 100% 370px;
        }
    }

    &__img-last{
        right: -45px;
        bottom: -10px;

        width: 467px;
        height: 261px;

        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(../img/retusz.png);

        @include media-breakpoint-down(md){
            bottom: -15px;

            width: 417px;
            height: 211px;
        }

        @include media-breakpoint-down(sm){
            bottom: -35px;
            right: 0;

            width: 400px;
            height: 200px;
        }

        @include media-breakpoint-down(tn){
            height: 100px;
            bottom: -100px;
            width: 200px;
        }
    }

    &__content-subtitle{
        margin: 0 auto 0 auto;
        max-width: 60%;

        font-size: 26px;
        font-weight: 400;

        @include media-breakpoint-down(md){
            font-size: 30px;
        }

        @include media-breakpoint-down(sm){
            max-width: 100%;

            font-size: 24px;
        }
        
        @include media-breakpoint-down(xs){
            font-size: 20px;
        }

        @include media-breakpoint-down(tn){
            font-size: 18px;
        }
    }

    &__content-title{
        margin-top: 40px;
        margin-bottom: 40px;

        text-align: right;
        text-transform: uppercase;
        font-size: 100px;
        line-height: 110px;
        font-family: $font-title;
        font-weight: bold;
        text-shadow: 1px 0 0 $color-font;

        @include media-breakpoint-down(md){
            margin-top: 30px;
            margin-bottom: 30px;

            font-size: 70px;
            line-height: 90px;
        }

        @include media-breakpoint-down(sm){
            text-align: center;
            font-size: 50px;
            line-height: 70px;
        }

        @include media-breakpoint-down(xs){
            margin-top: 20px;
            margin-bottom: 20px;

            text-align: left;
            font-size: 40px;
            line-height: 60px;
        }

        @include media-breakpoint-down(tn){
            margin-top: 15px;
            margin-bottom: 15px;

            font-size: 26px;
            line-height: 40px;
        }
    }

    &__content-text{
        margin: 0 auto 30px auto;
        max-width: 60%;

        font-size: 26px;
        line-height: 32px;

        &:last-child{
            margin-bottom: 0;
        }

        @include media-breakpoint-down(md){
            margin-bottom: 15px;

            font-size: 22px;
            line-height: 26px;
        }

        @include media-breakpoint-down(sm){
            max-width: 100%;

            font-size: 20px;
            line-height: 24px;
        }

        @include media-breakpoint-down(xs){
            font-size: 18px;
            line-height: 22px;
        }

        @include media-breakpoint-down(tn){
            font-size: 16px;
            line-height: 20px;
        }

        img{
            max-width: 40px;
            padding-left: 10px;
        }
    }
}